import type { SelectProps } from 'antd';


import { AimOutlined, CopyOutlined, InfoCircleOutlined, MinusCircleOutlined, PlusCircleOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Collapse, Divider, Form, FormInstance, FormListFieldData, Input, InputNumber, message, Row, Select, Space, Tooltip, Typography, Checkbox } from 'antd';
// import { PageHeader } from '@ant-design/pro-layout';

import { useEffect, useRef, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { Link } from 'react-router-dom';
import { editWorkflow } from '../services/signalApi';
import {
    FormSubmissionField,
    Integration,
    ProjectSnippet as ProjectSnippetType,
    Prompt as PromptType,
    RandomDict,
    WorkflowBlockOutputMethods,
    WorkflowBlockStepTemplateVar as WorkflowBlockStepTemplateVarType,
    WorkflowBlockStep as WorkflowBlockStepType,
    WorkflowConfigStepTypes,
    WorkflowConfigTriggerTypes,
    WorkflowConfig as WorkflowConfigType,
    WorkflowTrigger,
    WorkflowTriggerTimedScheduleType,
    Workflow as WorkflowType
} from '../types/types';
import { toTitleCase, isBetaUser } from '../utils';

import { useDataSets, useInfoSets, useIntegrations, useAiChatIntegrations } from '../app/apiQueries';
import { useAppSelector } from '../app/hooks';
import { CreatePrompt } from '../components/CreatePrompt';
import { EditPrompt } from '../components/EditPrompt';
import { PromptSearchSelect } from '../components/PromptSearchSelect';




import { extractBracketContents, custom_models_from_ai_chat_integrations } from '../utils';

import type { InputRef } from 'antd';

import { selectAccount, selectIsWorkflowDiscordUsernamePermitted } from '../features/login/loginSlice';
// import { WorkflowSubscribeModal } from '../components/WorkflowSubscribeModal'
// import { WorkflowEditModal } from '../components/WorkflowEditModal'
import 'draft-js/dist/Draft.css';
const { TextArea } = Input;

const MAXIMUM_STEPS = 10;


const modelLabelValues = [
    {"label": "GPT-4o", "value": "gpt-4o"},
    {"label": "GPT-4o mini", "value": "gpt-4o-mini"},
    {"label": "Llama 3 - 8b via Groq", "value": "groq.llama3-8b-8192"},
    {"label": "DeepSeek R1 Distill Llama 70b via Groq", "value": "groq.deepseek-r1-distill-llama-70b"},
    {"label": "Llama 3.1 - 8b via Groq", "value": "groq.llama-3.1-8b-instant"},
    {"label": "Llama 3.1 - 70b via Groq", "value": "groq.llama-3.1-70b-versatile"},
    {"label": "Llama 3.2 - 1b via Groq", "value": "groq.llama-3.2-1b-preview"},
    //   {"label": "Llama 3.1 - 405b via Groq", "value": "groq.llama-3.1-405b-reasoning"},
    {"label": "GPT-4 (Default 4 Model)", "value": "gpt-4"},
    {"label": "GPT-3.5 Turbo (Default 3.5 Model)", "value": "gpt-3.5-turbo"},
    {"label": "GPT-3.5 Turbo (2023-11-06 Model)", "value": "gpt-3.5-turbo-1106"},
    {"label": "GPT-3.5 Turbo (2023-06-13 Model)", "value": "gpt-3.5-turbo-0613"},
    {"label": "GPT-3.5 Turbo (2023-06-13 Model) with 16k Context", "value": "gpt-3.5-turbo-16k"},
    {"label": "GPT-4 Turbo (2023-11-06 Preview Model)", "value": "gpt-4-1106-preview"},
    // {"label": "GPT-4 with 32k Context", "value": "gpt-4-32k"},
    // {"label": "Claude2", "value": "anthropic.claude-v2"},
    // {"label": "Claude1", "value": "anthropic.claude-v1"},
    // {"label": "Claude1-Instant", "value": "anthropic.claude-instant-v1"},

    //   {"label": "Llama3 - 70b", "value": "groq.llama3-70b-8192"},
    //   {"label": "Mixtral 8x7b", "value": "groq.mixtral-8x7b-32768"},
    //   {"label": "Gemma 7b", "value": "groq.gemma-7b-it"},
    {"label": "Llama2 - 13b via Bedrock", "value": "meta.llama2-13b-chat-v1"},
    {"label": "xAI Grok Beta", "value": "xai.grok-beta"},
    {"label": "xAI Grok 2 12/12", "value": "xai.grok-2-1212"},
    // {"label": "Perplexity Llama 3.1 8b Instruct", "value": "pplx.llama-3.1-8b-instruct"},
    // {"label": "Perplexity Llama 3.1 70b Instruct", "value": "pplx.llama-3.1-70b-instruct"},
    // {"label": "Perplexity Llama 3.1 Sonar Small 128k Online", "value": "pplx.llama-3.1-sonar-small-128k-online"},
    {"label": "Perplexity Sonar", "value": "pplx.sonar"},
    {"label": "Perplexity Sonar Pro", "value": "pplx.sonar-pro"},
    // "pplx.llama-3.1-8b-instruct",
    // # "pplx.llama-3.1-70b-instruct",
    // "pplx.llama-3.1-sonar-small-128k-online",
    // mixtral
    // claude opus
]
const initial_value: {[key: string]: string} = {}
export const modelLabelsByValue = modelLabelValues.reduce((agg, mlv) => {
    agg[mlv.value] = mlv.label
    return agg
}, initial_value)

const weeklyOptionValues = [
    {value: "Mo", label: "Monday"},
    {value: "Tu", label: "Tuesday"},
    {value: "We", label: "Wednesday"},
    {value: "Th", label: "Thursday"},
    {value: "Fr", label: "Friday"},
    {value: "Sa", label: "Saturday"},
    {value: "Su", label: "Sunday"},
]

const weeklyOptions: SelectProps['options'] = weeklyOptionValues;

const weeklyLabelsByValue = weeklyOptionValues.reduce((agg, option) => {
    agg[option.value] = option.label
    return agg
}, initial_value)


function WorkflowTriggerSubmissionParams({trigger, forForm}: {trigger: FormListFieldData, forForm: boolean}) {
    return <Form.List
        name={[trigger.name, 'args', 'submission_params']}
    >
        {(submissionParams, { add: addSubmissionParam, remove: removeSubmissionParam }) => (
            <>
                {submissionParams.map((submissionParam, i) => (
                    // <div style={{border: "1px solid #333333", padding: '10px', margin: "5px 0px"}} key={`sub-params-${submissionParam.key}`}>
                    <div style={{}} key={`sub-params-${submissionParam.key}`}>
                        {submissionParams.length > 1 && i > 0 && (
                            <Divider type='horizontal' dashed></Divider>
                        )}
                        {submissionParams.length > 0 && (
                            <Row style={{marginBottom: '12px'}}>
                                <Col span={24}>
                                    <Tooltip placement='left' title="Remove Submission Param">
                                        <MinusCircleOutlined style={{float: "right"}} onClick={() => removeSubmissionParam(submissionParam.name)} />
                                    </Tooltip>
                                </Col>
                            </Row>

                        )}

                        <Form.Item
                            {...submissionParam}
                            key={`sub-params-field-${submissionParam.key}`}
                            label="Field"
                            name={[submissionParam.name, 'field']} // label, value, default_value
                            extra={<>Fields can be referenced in the following steps.</>}
                            rules={[
                                { required: true, message: 'Missing field' },
                                // { type: 'enum', enum: ['form_submit'], message: 'Not available to you. Sign up for access.'}
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        {forForm && (

                            <Form.Item
                                {...submissionParam}
                                key={`sub-params-label-${submissionParam.key}`}
                                label="Label"
                                name={[submissionParam.name, 'label']} // label, value, default_value
                                extra={<>How this field shows in the form.</>}
                                rules={[]}
                            >
                                <Input />
                            </Form.Item>
                        )}


                        <Form.Item
                            {...submissionParam}
                            key={`sub-params-default-${submissionParam.key}`}
                            label="Default Value"
                            name={[submissionParam.name, 'default_value']} // label, value, default_value
                            rules={[
                                // { required: true, message: 'Missing field' },
                                // { type: 'enum', enum: ['form_submit'], message: 'Not available to you. Sign up for access.'}
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        {forForm && (

                            <Form.Item
                                {...submissionParam}
                                key={`sub-params-placeholder-${submissionParam.key}`}
                                label="Placeholder"
                                name={[submissionParam.name, 'placeholder']} // label, value, default_value
                                rules={[]}
                            >
                                <Input />
                            </Form.Item>
                        )}


                        {forForm && (
                            <Form.Item
                                {...submissionParam}
                                key={`sub-params-checkbox-${submissionParam.key}`}
                                label="Field Type"
                                name={[submissionParam.name, 'field_type']} // label, value, default_value
                                rules={[
                                    // { required: true, message: 'Missing field' },
                                    // { type: 'enum', enum: ['form_submit'], message: 'Not available to you. Sign up for access.'}
                                ]}
                            >
                                <Select>
                                    <Select.Option key={'input'} value={'input'}>
                              Text Input
                                    </Select.Option>
                                    <Select.Option key={'text_area'} value={'text_area'}>
                              Text Area
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        )}




                    </div>
                ))}


                {submissionParams.length < 10 && (
                    <Space style={{justifyContent: "space-between", display: "flex"}}>
                        <span></span>
                        <Button onClick={() => addSubmissionParam({})} block icon={<PlusOutlined />}>
                      Add Form Field
                        </Button>
                    </Space>


                )}

            </>
        )}
    </Form.List>
}

function WorkflowTriggerFormTimed({
    trigger,
    schedule_type,
}: {
  trigger: FormListFieldData
  schedule_type: WorkflowTriggerTimedScheduleType
}) {



    const monthlyOptions: SelectProps['options'] = (new Array(31).fill(null).map((_, i) => i + 1)).map((i) => ({value: i, label: i}))

    return (
        <div>
            <Divider type='horizontal'>Timed</Divider>
            <Form.Item
                {...trigger}
                key={`timed-schedule-type-${trigger.key}}`}
                label="Schedule"
                name={[trigger.name, 'args', 'schedule', 'type']}
                rules={[{ required: true, message: 'Missing Schedule' }]}
                // extra={<>The message to send. Special replacements are: {'{symbol}'}, {'{value}'}, {'{context.field}'}</>}
            >
                <Select>
                    <Select.Option key={'daily'} value={'daily'}>
                  Daily
                    </Select.Option>
                    <Select.Option key={'weekly'} value={'weekly'}>
                  Weekly
                    </Select.Option>
                    <Select.Option key={'monthly'} value={'monthly'}>
                  Monthly
                    </Select.Option>
                </Select>
            </Form.Item>

            {schedule_type === 'weekly' && (
                <Form.Item
                    label="Weekday(s)"
                    name={[trigger.name, 'args', 'schedule', 'week_days']}
                    tooltip={{ title: 'Which days of the week to execute this', icon: <InfoCircleOutlined /> }}
                >
                    <Select
                        mode="tags"
                        style={{ width: '100%' }}
                        placeholder="Monday, Tuesday..."
                        // onChange={handleChange}
                        options={weeklyOptions}
                    />
                </Form.Item>
            )}

            {schedule_type === 'monthly' && (
                <Form.Item
                    label="Days of the Month(s)"
                    name={[trigger.name, 'args', 'schedule', 'month_days']}
                    tooltip={{ title: 'Which days of the month to execute this', icon: <InfoCircleOutlined /> }}
                >
                    <Select
                        mode="tags"
                        style={{ width: '100%' }}
                        placeholder="1, 2..."
                        // onChange={handleChange}
                        options={monthlyOptions}
                    />
                </Form.Item>
            )}

            <Form.Item
                {...trigger}
                key={`timed-schedule-hour-${trigger.key}}`}
                label="Hour"
                name={[trigger.name, 'args', 'schedule_hour']}
                rules={[{ required: true, message: 'Missing Schedule Hour' }]}
                extra={<>The hour is 0-23 for Midnight to 11pm in UTC. Timezone support coming soon.</>}
            >
                <InputNumber min={0} max={23} />
            </Form.Item>

            <Divider type='horizontal'>Form Fields</Divider>

            <WorkflowTriggerSubmissionParams trigger={trigger} forForm={false} />



        </div>
    )
}


function WorkflowTriggerFormFormSubmit({trigger}: {trigger: FormListFieldData}) {
    return (
        <div>
            <Divider type='horizontal'>Form Configuration</Divider>
            <Form.Item
                {...trigger}
                key={`form-title-${trigger.key}}`}
                label="Form Title"
                name={[trigger.name, 'args', 'form_title']}
                // rules={[{ required: true, message: 'Missing Schedule' }]}
                // extra={<>The message to send. Special replacements are: {'{symbol}'}, {'{value}'}, {'{context.field}'}</>}
            >
                <Input />
            </Form.Item>
            <Form.Item
                {...trigger}
                key={`form-description-${trigger.key}}`}
                label="Form Description"
                name={[trigger.name, 'args', 'form_description']}
                // rules={[{ required: true, message: 'Missing Schedule' }]}
                // extra={<>The message to send. Special replacements are: {'{symbol}'}, {'{value}'}, {'{context.field}'}</>}
            >
                <Input />
            </Form.Item>
            <Form.Item
                {...trigger}
                key={`form-button-text-${trigger.key}}`}
                label="Form Submit Button"
                name={[trigger.name, 'args', 'form_button_text']}
                // rules={[{ required: true, message: 'Missing Schedule' }]}
                // extra={<>The message to send. Special replacements are: {'{symbol}'}, {'{value}'}, {'{context.field}'}</>}
            >
                <Input />
            </Form.Item>
            {/* <Form.Item
              {...trigger}
              key={`form-submission-cap-${trigger.key}}`}
              label="Daily Limit"
              name={[trigger.name, 'args', 'form_submission_daily_cap']}
              // rules={[{ required: true, message: 'Missing Schedule' }]}
              extra={<>Optionally impose a limit on daily executions.</>}
          >
              <InputNumber min={0} />
          </Form.Item> */}

            {/* <Typography.Paragraph>Form Submission Fields - make this look better, maybe little cards?</Typography.Paragraph> */}
            <Divider type='horizontal'>Form Fields</Divider>

            <WorkflowTriggerSubmissionParams trigger={trigger} forForm={true}/>

        </div>
    )
}

function WorkflowTriggerFormForTriggerType({
    trigger, trigger_type, timed_schedule_type
}: {
  trigger: FormListFieldData,
  trigger_type: WorkflowConfigTriggerTypes,
  timed_schedule_type: WorkflowTriggerTimedScheduleType
}) {
// const WorkflowTriggerFormForTriggerType = (trigger: FormListFieldData, trigger_type: WorkflowConfigTriggerTypes) => {
    if (trigger_type === undefined || trigger_type === null) {
        return <></>
    }

    if (trigger_type === "timed"){
        return <WorkflowTriggerFormTimed trigger={trigger}  schedule_type={timed_schedule_type} />
        // return workflowTriggerFormTimed(trigger)
    }

    if (trigger_type === "form_submit"){
        return <WorkflowTriggerFormFormSubmit trigger={trigger} />
        // return workflowTriggerFormFormSubmit(trigger)
    }

    return <></>
}

function WorkflowStepFormExecutePromptTemplateVar({
    templateVar,
    available_refs,
}: {
  templateVar: FormListFieldData
  available_refs: SelectProps['options']
}) {
    console.log("WorkflowStepFormExecutePromptTemplateVar", {templateVar})

    const [isHardcoded, setIsHardcoded] = useState<boolean>(false);
    // const upstreamRefOptions: SelectProps['options'] = available_refs.map((i) => ({value: i, label: i}))
    const upstreamRefOptions: SelectProps['options'] = [...(available_refs || []), {label: 'Hardcoded', value: ''}]

    // const upstreamRefOptions: SelectProps['options'] = [
    //     'Project: Context',
    //     'Project: Audience',
    //     'Project: Fears, Goals, Dreams',
    //     'Form: Subject',
    //     'Step 1 Prompt: Rezzy',
    //     'Hardcoded',
    //     // '#Learning'
    // ].map((i) => ({value: i, label: i}))

    const onSelect = (value: string) => {
        console.log("onSelect", {value})
        if (value === '') {
            setIsHardcoded(true)
        } else {
            setIsHardcoded(false)
        }
    }

    return <>
        <Form.Item
            {...templateVar}
            key={`sub-params-field-${templateVar.key}`}
            label="Field"
            name={[templateVar.name, 'field']} // label, value, default_value
            rules={[
                { required: true, message: 'Missing field' },
                // { type: 'enum', enum: ['form_submit'], message: 'Not available to you. Sign up for access.'}
            ]}
            extra={<span>Which square-bracketed text in the Prompt to replace.</span>}
        >
            <Input />
        </Form.Item>

        <Form.Item
            {...templateVar}
            key={`sub-params-ref-${templateVar.key}`}
            label="Reference"
            name={[templateVar.name, 'upstream_ref']} // label, value, default_value
            rules={[
                // { required: true, message: 'Missing field' },
                // { type: 'enum', enum: ['form_submit'], message: 'Not available to you. Sign up for access.'}
            ]}
            extra={<span>Reference a previously set field.</span>}
        >
            {/* <Input /> */}
            <Select
                // showSearch
                // style={{ width: '100%' }}
                placeholder="A previously set reference"
                // optionFilterProp="children"
                onSelect={onSelect}
                // onSearch={onSearch}
                // filterOption={(input, option) =>
                //     (option?.label && typeof option.label === 'string' ? option.label : '').toLowerCase().includes(input.toLowerCase())
                // }
                // onChange={handleChange}
                options={upstreamRefOptions}
            />
        </Form.Item>

        <Form.Item
            {...templateVar}
            key={`sub-params-value-${templateVar.key}`}
            label="Value"
            name={[ templateVar.name, 'value']} // label, value, default_value
            rules={[
                // { required: true, message: 'Missing field' },
                // { type: 'enum', enum: ['form_submit'], message: 'Not available to you. Sign up for access.'}
            ]}
            extra={<span>Hardcode the value to inject into the prompt</span>}
        >
            <Input disabled={!isHardcoded}/>
        </Form.Item>


    </>

}

function WorkflowStepSetTemplateVars({
    step,
    getRefsForStep,
    form,
    text,
}: {
  step: FormListFieldData
  getRefsForStep: (step: FormListFieldData) => SelectProps['options']
  form: FormInstance
  text: string
}) {
    const available_refs = getRefsForStep(step)
    return (
        <Form.List name={[step.name, 'args', 'set_template_vars']}>
            {(templateVars, { add: addTemplateVars, remove: removeTemplateVars }) => (
                <>
                    {templateVars.map((templateVar, i) => (
                        // <div style={{border: "1px solid #333333", padding: '10px', margin: "5px 0px"}} key={`sub-params-${templateVar.key}`}>
                        <div style={{}} key={`sub-params-${templateVar.key}`}>
                            {templateVars.length > 1 && i > 0 && (
                                <Divider type='horizontal' dashed></Divider>
                            )}
                            {templateVars.length > 1 && (
                                <Row style={{marginBottom: '12px'}}>
                                    <Col span={24}>
                                        <Tooltip placement='left' title="Remove">
                                            <MinusCircleOutlined style={{float: "right"}} onClick={() => removeTemplateVars(templateVar.name)} />
                                        </Tooltip>
                                    </Col>
                                </Row>
                            )}



                            <WorkflowStepFormExecutePromptTemplateVar templateVar={templateVar} available_refs={available_refs}/>
                        </div>
                    ))}


                    {templateVars.length < 10 && (
                        <Row style={{marginBottom: '12px'}}>
                            <Col xs={{offset: 0, span: 24}} md={{offset: 16, span:8}}>
                                <Button style={{float: 'right', width: 'unset'}} onClick={() => addTemplateVars({})} block icon={<PlusOutlined />}>
                                    {text}
                                </Button>
                            </Col>
                        </Row>
                    )}

                </>
            )}
        </Form.List>
    )

}


function WorkflowStepFormExecutePrompt({
    step,
    workflowPrompts,
    getRefsForStep,
    form,
    clearStepOutputArgs,
    setStepTemplateVars,
    setStepPromptId,
}: {
  step: FormListFieldData
  workflowPrompts: PromptType[]
  getRefsForStep: (step: FormListFieldData) => SelectProps['options']
  form: FormInstance
  clearStepOutputArgs: (step: FormListFieldData) => void
  setStepTemplateVars: (step: FormListFieldData, availableFields: string[]) => void
  setStepPromptId: (step: FormListFieldData, prompt_id: string) => void
}) {
    const account = useAppSelector(selectAccount);
    const [selectedPrompt, setSelectedPrompt] = useState<PromptType | null>(null);
    const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
    const [selectedOutputMethod, setSelectedOutputMethod] = useState<WorkflowBlockOutputMethods>('whole_prompt_response');
    const { ai_chat_integrations } = useAiChatIntegrations(account)
    const custom_models = custom_models_from_ai_chat_integrations(ai_chat_integrations || []);
    console.log("ai_chat_integrations", {ai_chat_integrations, custom_models})
    const inputRef = useRef<InputRef>(null);



    useEffect(() => {
        const existingPromptId = form.getFieldValue(["step_definition", "args", "block_steps", step.name, "args", "prompt_id"])
        console.log("existingPromptId", {existingPromptId})
        if (existingPromptId && selectedPrompt === null) {
            console.log("requestingExistingPromptId", {existingPromptId})
            const matchingPrompts = workflowPrompts.filter((p) => p.id === existingPromptId)
            if (matchingPrompts.length > 0) {
                setSelectedPrompt(matchingPrompts[0])
            }
        }

    }, [])

    return (
        <div>
            <Divider type='horizontal'>Configure a Prompt to be executed</Divider>
            <Form.Item
                {...step}
                key={`form-prompt-id-${step.key}}`}
                // label="Prompt"
                name={[step.name, 'args', 'prompt_id']}
                hidden={true}
                // rules={[{ required: true, message: 'Missing Schedule' }]}
                // extra={<>The message to send. Special replacements are: {'{symbol}'}, {'{value}'}, {'{context.field}'}</>}
            >
                <Input ref={inputRef} />
            </Form.Item>


            <PromptSearchSelect
                isSearchModalOpen={isSearchOpen}
                onSelect={(prompt) => {
                    setIsSearchOpen(false)
                    setSelectedPrompt(prompt)
                    const varsToSet = extractBracketContents(prompt.content || '')
                    console.log("varsToSet", {varsToSet})
                    setStepTemplateVars(step, varsToSet)
                    setStepPromptId(step, prompt.id)
                    // set template vars fields as prompt
                }}
                onClose={() => setIsSearchOpen(false)}
            />


            <Form.Item label="Prompt" required={true}>



                {selectedPrompt && (
                    <>
                        <Typography.Paragraph>{selectedPrompt.name}</Typography.Paragraph>
                        <Typography.Paragraph style={{border: '1px solid #555', padding: '10px', margin: "12px 0 24px", fontSize: '0.8rem', whiteSpace: 'pre-line'}}>{selectedPrompt.content}</Typography.Paragraph>
                    </>
                )}
                {!selectedPrompt && (
                    <>
                        <Typography.Paragraph>No Prompt Loaded</Typography.Paragraph>
                    </>
                )}

                <Row>
                    <Col offset={0} span={24}>
                        <Space direction='horizontal' style={{float: 'right'}} wrap>
                            {selectedPrompt && (
                                <EditPrompt prompt={selectedPrompt} buttonType='default' buttonText='Edit Selected Prompt' onEdit={(prompt) => {
                                    if (prompt === null) {
                                        return
                                    }
                                    setSelectedPrompt(prompt)
                                    const varsToSet = extractBracketContents(prompt.content || '')
                                    console.log("varsToSet", {varsToSet})
                                    setStepTemplateVars(step, varsToSet)
                                    setStepPromptId(step, prompt.id)
                                }} />
                            )}
                            <CreatePrompt buttonType='default' buttonText="Create Prompt" onCreate={(prompt) => {
                                if (prompt === null) {
                                    return
                                }
                                setSelectedPrompt(prompt)
                                const varsToSet = extractBracketContents(prompt.content || '')
                                console.log("varsToSet", {varsToSet})
                                setStepTemplateVars(step, varsToSet)
                                setStepPromptId(step, prompt.id)
                            }} />
                            <Button style={{float: 'right'}} onClick={() => setIsSearchOpen(true)} icon={<SearchOutlined />}>Search for Prompt</Button>
                        </Space>

                    </Col>
                </Row>

            </Form.Item>

            <Form.Item
                {...step}
                key={`form-prompt-model-${step.key}}`}
                label="Model"
                name={[step.name, 'args', 'model']}
                // rules={[
                //     { type: 'enum', enum: ['gpt-3.5-turbo', 'gpt-4'], message: 'Not available to you yet.'}
                // //    {required: true, message: 'Missing Schedule'}
                // ]}
                // extra={<>The message to send. Special replacements are: {'{symbol}'}, {'{value}'}, {'{context.field}'}</>}
            >
                <Select>
                    {modelLabelValues.map((mlv) => (
                        <Select.Option key={mlv.value} value={mlv.value}>
                            {mlv.label}
                        </Select.Option>
                    ))}
                    {(custom_models || []).map((cm) => (
                        <Select.Option key={cm.id} value={cm.id}>
                            {cm.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            {/* <Typography.Paragraph>Form Submission Fields - make this look better, maybe little cards?</Typography.Paragraph> */}
            <Divider type='horizontal'>Inject Prompt Vars</Divider>

            {/* <Typography.Paragraph>Inject Prompt Vars</Typography.Paragraph> */}

            <WorkflowStepSetTemplateVars step={step} getRefsForStep={getRefsForStep} form={form} text={"Add Prompt Var"}/>
            <Divider type='horizontal'>Configure Output</Divider>

            <Form.Item
                {...step}
                key={`step-output-method-${step.key}`}
                label="Output Method"
                name={[step.name, 'args', 'output_method', 'output_method_type']}
                rules={[
                    { required: true, message: 'Missing Output Method' },
                    // { type: 'enum', enum: ['form_submit'], message: 'Not available to you. Sign up for access.'}
                ]}
            >
                <Select onChange={(newOutputMethod) => {
                    console.log("selectOnChange", {newOutputMethod})
                    setSelectedOutputMethod(newOutputMethod)
                    clearStepOutputArgs(step)
                }}>
                    <Select.Option key={'whole_prompt_response'} value={'whole_prompt_response'}>
                          Use Whole Prompt Response
                    </Select.Option>
                    <Select.Option key={'parsed_single'} value={'parsed_single'}>
                          Parse Prompt Response
                    </Select.Option>
                    <Select.Option key={'parsed_list'} value={'parsed_list'}>
                          Parse Prompt Response as a List
                    </Select.Option>

                </Select>
            </Form.Item>

            <WorkflowStepFormForOutputMethod step={step} step_type={'execute_prompt'} output_method={selectedOutputMethod}/>

        </div>
    )
}


function WorkflowStepFormSendEmail({
    step,
    getRefsForStep,
    form,
    clearStepOutputArgs,
}: {
  step: FormListFieldData,
  getRefsForStep: (step: FormListFieldData) => SelectProps['options']
  form: FormInstance,
  clearStepOutputArgs: (step: FormListFieldData) => void}
) {
    return (
        <div>
            <Divider type='horizontal'>Configure an email to be sent</Divider>
            <Form.Item
                {...step}
                key={`form-email-subject-${step.key}}`}
                label="Subject"
                name={[step.name, 'args', 'subject_template']}
                // rules={[{ required: true, message: 'Missing Schedule' }]}
                extra={<>The subject of the email to send. Include square-brackets to substitute in text.</>}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                {...step}
                key={`form-email-content-${step.key}}`}
                label="Content"
                name={[step.name, 'args', 'content_markdown_template']}
                // rules={[{ required: true, message: 'Missing Schedule' }]}
                extra={<>The content of the email to send. Include square-brackets to substitute in text.</>}
            >
                <TextArea
                    showCount
                    maxLength={1600}
                    style={{ height: 120 }}
                    // onChange={onChange}
                    placeholder="The body of your email in markdown format..."
                />
            </Form.Item>
            <Divider type='horizontal'>Inject Vars in to Email</Divider>

            <WorkflowStepSetTemplateVars step={step} getRefsForStep={getRefsForStep} form={form} text={'Add Email Var'}/>

        </div>
    )
}


function WorkflowStepFormSetProjectSnippet({
    step,
    getRefsForStep,
    form,
    clearStepOutputArgs,
}: {
  step: FormListFieldData,
  getRefsForStep: (step: FormListFieldData) => SelectProps['options']
  form: FormInstance,
  clearStepOutputArgs: (step: FormListFieldData) => void}
) {
    return (
        <div>
            <Divider type='horizontal'>Configure an snippet to be updated</Divider>
            <Form.Item
                {...step}
                key={`form-set-snippet-name-${step.key}}`}
                label="Snippet Name"
                name={[step.name, 'args', 'snippet_name']}
                // rules={[{ required: true, message: 'Missing Schedule' }]}
                extra={<>The name of the Snippet to set. If it does not exist, it will get created.</>}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                {...step}
                key={`form-email-content-${step.key}}`}
                label="Content"
                name={[step.name, 'args', 'snippet_content_template']}
                // rules={[{ required: true, message: 'Missing Schedule' }]}
                extra={<>The content of the snippet to set. Include square-brackets to substitute in text.</>}
            >
                <TextArea
                    showCount
                    maxLength={1600}
                    style={{ height: 120 }}
                    // onChange={onChange}
                    placeholder="The new content of your snippet..."
                />
            </Form.Item>
            <Divider type='horizontal'>Inject Vars in to Snippet</Divider>

            <WorkflowStepSetTemplateVars step={step} getRefsForStep={getRefsForStep} form={form} text={'Add Snippet Var'}/>

        </div>
    )
}


function WorkflowStepFormSetResult({
    step,
    getRefsForStep,
    form,
    clearStepOutputArgs,
}: {
  step: FormListFieldData,
  getRefsForStep: (step: FormListFieldData) => SelectProps['options']
  form: FormInstance,
  clearStepOutputArgs: (step: FormListFieldData) => void}
) {
    return (
        <div>
            <Divider type='horizontal'>Configure an the result to set</Divider>

            <Form.Item
                {...step}
                key={`form-result-content-${step.key}}`}
                label="Content"
                name={[step.name, 'args', 'result_template']}
                // rules={[{ required: true, message: 'Missing Schedule' }]}
                extra={<>The content of the snippet to set. Include square-brackets to substitute in text.</>}
            >
                <TextArea
                    showCount
                    maxLength={1600}
                    style={{ height: 120 }}
                    // onChange={onChange}
                    placeholder="The new content of the result..."
                />
            </Form.Item>
            <Divider type='horizontal'>Inject Vars in to Result</Divider>

            <WorkflowStepSetTemplateVars step={step} getRefsForStep={getRefsForStep} form={form} text={'Add Result Var'}/>

        </div>
    )
}

function WorkflowStepFormLookupInfoSet({
    step,
    getRefsForStep,
    form,
    clearStepOutputArgs,
}: {
    step: FormListFieldData,
    getRefsForStep: (step: FormListFieldData) => SelectProps['options']
    form: FormInstance,
    clearStepOutputArgs: (step: FormListFieldData) => void}
) {
    const account = useAppSelector(selectAccount);

    const [selectedOutputMethod, setSelectedOutputMethod] = useState<WorkflowBlockOutputMethods>('whole_prompt_response');
    const [selectedInfoSetId, setSelectedInfoSetId] = useState<string | null>(null);



    useEffect(() => {
        const existingInfoSetId = form.getFieldValue(["step_definition", "args", "block_steps", step.name, "args", "info_set_id"])
        console.log("existingInfoSetId", {existingInfoSetId})
        if (existingInfoSetId && selectedInfoSetId === null) {
            setSelectedInfoSetId(existingInfoSetId)
        }

    }, [])

    const {info_sets, info_sets_count, isFetchingInfoSets} = useInfoSets(account);
    const {data_sets } = useDataSets(account);

    const selectedInfoSet = (info_sets || []).find((v) => v.id === selectedInfoSetId)
    console.log("infoSetsQuery", {info_sets_count, info_sets, isFetchingInfoSets})

    const clearStepLookupParamsArgs = (step: FormListFieldData, newInfoSetId: string) => {
        const chosenInfoSet = (info_sets || []).find((v) => v.id === newInfoSetId)
        const defaultsDefault: RandomDict = {}
        const defaults = chosenInfoSet?.source_lookup_params.fields.reduce((agg, field, index) => {
            if (field.default_value) {
                agg[field.field] = field.default_value
            }
            return agg
        }, defaultsDefault)

        console.log("clear step output", {step})
        form.setFieldValue(["step_definition", "args", "block_steps", step.name, "args", "source_lookup_params"], defaults)
    }

    return (
        <div>
            <Divider type='horizontal'>Configure the Lookup</Divider>

            <Form.Item
                {...step}
                key={`form-lookup-info-set-id-${step.key}}`}
                label="Lookup Source"
                name={[step.name, 'args', 'info_set_id']}
                rules={[
                    {required: true, message: 'Missing Lookup Source'}
                ]}
                // extra={<>The message to send. Special replacements are: {'{symbol}'}, {'{value}'}, {'{context.field}'}</>}
            >
                <Select onChange={(newInfoSet) => {
                    console.log("selectOnChange", {newInfoSet})
                    setSelectedInfoSetId(newInfoSet)
                    clearStepLookupParamsArgs(step, newInfoSet)
                    //   clearStepOutputArgs(step)
                }}>
                    {(info_sets || []).map((info_set) => (
                        <Select.Option key={info_set.id} value={info_set.id}>
                            {info_set.name}{info_set.enabled ? '' : ' (disabled)'}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            {selectedInfoSet && (
                <>
                    {/* <Typography.Paragraph>Selected... {selectedInfoSet.name}</Typography.Paragraph> */}
                    {selectedInfoSet.source_lookup_params.fields.map((submissionParam) => (
                        <div key={`form-lookup-info-set-source-lookup-params-wrapper-${submissionParam.field}}`}>
                            {(submissionParam.field_type || 'input') === 'input' && (
                                <Form.Item
                                    key={`form-lookup-info-set-source-lookup-param-${submissionParam.field}}`}
                                    label={('label' in submissionParam && submissionParam.label != undefined) ? submissionParam.label : submissionParam.field}
                                    name={[step.name, 'args', 'source_lookup_params', submissionParam.field]}
                                    rules={[
                                        ...(submissionParam.required === true ?
                                                [{required: true, message: `Missing Lookup Source ${('label' in submissionParam && submissionParam.label != undefined) ? submissionParam.label : submissionParam.field}`}] : [])
                                    ]}
                                    extra={submissionParam.description ? <span>{submissionParam.description}</span> : undefined}


                                >
                                    {submissionParam.field === "data_set_id" && (
                                        <Select
                                            style={{ width: "100%" }}
                                            placeholder="Select Data Set"
                                        >
                                            {(data_sets || []).map((data_set) => (
                                                <Select.Option key={data_set.id} value={data_set.id}>
                                                    {data_set.name} ({data_set.id})
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                    {submissionParam.field !== "data_set_id" && (
                                        <Input defaultValue={submissionParam.default_value ? submissionParam.default_value : undefined} {...(submissionParam.placeholder ? {placeholder: submissionParam.placeholder} : {})} />

                                    )}
                                </Form.Item>

                            )}
                            {(submissionParam.field_type || 'input') === 'text_area' && (

                                <Form.Item
                                    // {...trigger}
                                    key={`form-lookup-info-set-source-lookup-param-ta-${submissionParam.field}}`}
                                    label={('label' in submissionParam && submissionParam.label != undefined) ? submissionParam.label : submissionParam.field}
                                    name={[step.name, 'args', 'source_lookup_params', submissionParam.field]}

                                >
                                    <Input.TextArea defaultValue={submissionParam.default_value ? submissionParam.default_value : undefined} style={{ height: 120 }} {...(submissionParam.placeholder ? {placeholder: submissionParam.placeholder} : {})} />
                                </Form.Item>
                            )}
                        </div>
                    ))}
                </>

            )}


            <Divider type='horizontal'>Inject Vars in to Lookup</Divider>

            <WorkflowStepSetTemplateVars step={step} getRefsForStep={getRefsForStep} form={form} text={'Add Lookup Var'}/>

            <Divider type='horizontal'>Configure Output</Divider>

            <Form.Item
                {...step}
                key={`step-output-method-${step.key}`}
                label="Output Method"
                name={[step.name, 'args', 'output_method', 'output_method_type']}
                rules={[
                    { required: true, message: 'Missing Output Method' },
                    // { type: 'enum', enum: ['form_submit'], message: 'Not available to you. Sign up for access.'}
                ]}
            >
                <Select onChange={(newOutputMethod) => {
                    console.log("selectOnChange", {newOutputMethod})
                    setSelectedOutputMethod(newOutputMethod)
                    clearStepOutputArgs(step)
                }}>
                    <Select.Option key={'whole_prompt_response'} value={'whole_prompt_response'}>
                            Use Whole Prompt Response
                    </Select.Option>
                    <Select.Option key={'parsed_single'} value={'parsed_single'}>
                            Parse Prompt Response
                    </Select.Option>
                    <Select.Option key={'parsed_list'} value={'parsed_list'}>
                            Parse Prompt Response as a List
                    </Select.Option>

                </Select>
            </Form.Item>

            <WorkflowStepFormForOutputMethod step={step} step_type={'lookup_info_set'} output_method={selectedOutputMethod}/>

        </div>
    )
}

function WorkflowStepFormRunWorkflows({
    step,
    getRefsForStep,
    form,
    clearStepOutputArgs,
}: {
    step: FormListFieldData,
    getRefsForStep: (step: FormListFieldData) => SelectProps['options']
    form: FormInstance,
    clearStepOutputArgs: (step: FormListFieldData) => void}
) {
    const account = useAppSelector(selectAccount);
    const [selectedOutputMethod, setSelectedOutputMethod] = useState<WorkflowBlockOutputMethods>('whole_prompt_response');



    return (
        <div>
            <Divider type='horizontal'>Configure the Workflows</Divider>
            <Typography.Paragraph>Chooses an appropriate Workflow to run based on the Workflows made available to it and a Prompt that you set.</Typography.Paragraph>

            <Form.Item
                {...step}
                key={`form-workflow-name-whitelist-${step.key}}`}
                label="Workflow Name Search"
                name={[step.name, 'args', 'workflow_name_whitelist']}
                rules={[
                    {required: true, message: 'Set Workflow Name Search'}
                ]}
                extra={<>Words that the workflow must contain to be considered.<br/>Case insensitive. Comma-separate for multiple.</>}
            >
                <Input placeholder='sub, task, ...'/>
            </Form.Item>

            <Form.Item
                {...step}
                key={`form-workflow-instruction-${step.key}}`}
                label="Workflow Instruction"
                name={[step.name, 'args', 'workflow_instruction']}
                rules={[
                    {required: true, message: 'Set Workflow Instruction'}
                ]}
                extra={<>Instruction to use when selecting a Workflow</>}
            >
                <TextArea
                    showCount
                    maxLength={1600}
                    style={{ height: 120 }}
                    // onChange={onChange}
                    placeholder="Select a Workflow that fulfills the following ask: [ask]"
                />
            </Form.Item>

            <Form.Item
                {...step}
                key={`form-workflow-always-run-${step.key}}`}
                label="Always Run One?"
                name={[step.name, 'args', 'always_run_one_or_fail']}
                extra={<>Just run one first that matches alphabetically, ignoring the instruction.</>}
                valuePropName="checked"
            >
                <Checkbox />
            </Form.Item>

            {/* <Form.Item
                {...step}
                key={`form-workflow-always-run-${step.key}}`}
                label="Always Run One?"
                name={[step.name, 'args', 'always_run_one_or_fail']}
                rules={[
                    {required: true, message: 'Set Workflow Instruction'}
                ]}
                extra={<>Instruction to use when selecting a Workflow</>}
            >
                <TextArea
                    showCount
                    maxLength={1600}
                    style={{ height: 120 }}
                    // onChange={onChange}
                    placeholder="Select a Workflow that fulfills the following ask: [ask]"
                />
            </Form.Item> */}

            



            <Divider type='horizontal'>Inject Vars in to Lookup</Divider>

            <WorkflowStepSetTemplateVars step={step} getRefsForStep={getRefsForStep} form={form} text={'Add Lookup Var'}/>

            <Divider type='horizontal'>Configure Output</Divider>

            <Form.Item
                {...step}
                key={`step-output-method-${step.key}`}
                label="Output Method"
                name={[step.name, 'args', 'output_method', 'output_method_type']}
                rules={[
                    { required: true, message: 'Missing Output Method' },
                    // { type: 'enum', enum: ['form_submit'], message: 'Not available to you. Sign up for access.'}
                ]}
            >
                <Select onChange={(newOutputMethod) => {
                    console.log("selectOnChange", {newOutputMethod})
                    setSelectedOutputMethod(newOutputMethod)
                    clearStepOutputArgs(step)
                }}>
                    <Select.Option key={'whole_prompt_response'} value={'whole_prompt_response'}>
                            Use Whole Prompt Response
                    </Select.Option>
                    <Select.Option key={'parsed_single'} value={'parsed_single'}>
                            Parse Prompt Response
                    </Select.Option>
                    <Select.Option key={'parsed_list'} value={'parsed_list'}>
                            Parse Prompt Response as a List
                    </Select.Option>

                </Select>
            </Form.Item>

            <WorkflowStepFormForOutputMethod step={step} step_type={'lookup_info_set'} output_method={selectedOutputMethod}/>

        </div>
    )
}


function WorkflowStepFormPostToX({
    step,
    getRefsForStep,
    form,
    clearStepOutputArgs,
}: {
    step: FormListFieldData,
    getRefsForStep: (step: FormListFieldData) => SelectProps['options']
    form: FormInstance,
    clearStepOutputArgs: (step: FormListFieldData) => void}
) {
    const account = useAppSelector(selectAccount);
    const [selectedOutputMethod, setSelectedOutputMethod] = useState<WorkflowBlockOutputMethods>('whole_prompt_response');
    // get integrations

    const [selectedIntegrationId, setSelectedIntegrationId] = useState<string | null>(null);



    useEffect(() => {
        const existingIntegrationId = form.getFieldValue(["step_definition", "args", "block_steps", step.name, "args", "integration_id"])
        console.log("existingIntegrationId", {existingIntegrationId})
        if (existingIntegrationId && selectedIntegrationId === null) {
            setSelectedIntegrationId(existingIntegrationId)
        }

    }, [])

    const {integrations} = useIntegrations(account)

    // const selectedIntegration = (integrations || []).find((v) => v.id === selectedIntegrationId)


    return (
        <div>
            <Divider type='horizontal'>Configure Posting to X</Divider>

            <Form.Item
                {...step}
                key={`form-post-content-${step.key}}`}
                label="Content"
                name={[step.name, 'args', 'content']}
                rules={[
                    {required: true, message: 'Set Content'}
                ]}
                extra={<>The content of the post to X</>}
            >
                <TextArea
                    showCount
                    maxLength={240}
                    style={{ height: 120 }}
                    // onChange={onChange}
                    placeholder="three things not to miss! [things]"
                />
            </Form.Item>

            <Form.Item
                {...step}
                key={`form-post-integration-id-${step.key}}`}
                label="Integration"
                name={[step.name, 'args', 'integration_id']}
                rules={[
                    {required: true, message: 'Set Integration'}
                ]}
                extra={<>The integration to post to</>}
            >
                <Select onChange={(newIntegrationId) => {
                    console.log("selectOnChange", {newIntegrationId})
                    setSelectedIntegrationId(newIntegrationId)
                    // clearStepLookupParamsArgs(step, newInfoSet)
                    //   clearStepOutputArgs(step)
                }}>
                    {(integrations || []).filter((integration) => integration.type === "x_authentication").map((integration) => (
                        <Select.Option key={integration.id} value={integration.id}>
                            {integration.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>


            <Divider type='horizontal'>Inject Vars in to Lookup</Divider>

            <WorkflowStepSetTemplateVars step={step} getRefsForStep={getRefsForStep} form={form} text={'Add Lookup Var'}/>

            <Divider type='horizontal'>Configure Output</Divider>

            <Form.Item
                {...step}
                key={`step-output-method-${step.key}`}
                label="Output Method"
                name={[step.name, 'args', 'output_method', 'output_method_type']}
                rules={[
                    { required: true, message: 'Missing Output Method' },
                    // { type: 'enum', enum: ['form_submit'], message: 'Not available to you. Sign up for access.'}
                ]}
            >
                <Select onChange={(newOutputMethod) => {
                    console.log("selectOnChange", {newOutputMethod})
                    setSelectedOutputMethod(newOutputMethod)
                    clearStepOutputArgs(step)
                }}>
                    <Select.Option key={'whole_prompt_response'} value={'whole_prompt_response'}>
                            Use Whole Prompt Response
                    </Select.Option>
                    <Select.Option key={'parsed_single'} value={'parsed_single'}>
                            Parse Prompt Response
                    </Select.Option>
                    <Select.Option key={'parsed_list'} value={'parsed_list'}>
                            Parse Prompt Response as a List
                    </Select.Option>

                </Select>
            </Form.Item>

            <WorkflowStepFormForOutputMethod step={step} step_type={'post_to_x'} output_method={selectedOutputMethod}/>

        </div>
    )
}



function WorkflowStepFormForOutputMethod({step, step_type, output_method}: {
  step: FormListFieldData,
  step_type: WorkflowConfigStepTypes,
  output_method: WorkflowBlockOutputMethods
}) {
    if (output_method === 'whole_prompt_response') {
        return <>
            <Form.Item
                {...step}
                key={`step-output-method-set-ref-${step.key}`}
                label="Set Ref"
                name={[step.name, 'args', 'output_method', 'args', 'set_ref']}
                rules={[
                    { required: true, message: 'Missing Reference Name' },
                ]}
            >
                <Input placeholder='response'/>
            </Form.Item>
        </>
    }
    if (output_method === 'parsed_single') {
        return <span>Not enabled at this time</span>
        // return <>
        //     <Form.Item
        //         {...step}
        //         key={`step-output-method-set-ref-${step.key}`}
        //         label="Set Ref"
        //         name={[step.name, 'args', 'output_method', 'args', 'set_ref']}
        //         // rules={[
        //         //     { required: true, message: 'Missing trigger' },
        //         //     { type: 'enum', enum: ['form_submit'], message: 'Not available to you. Sign up for access.'}
        //         // ]}
        //         >
        //             <Input />
        //     </Form.Item>
        // </>
    }
    if (output_method === 'parsed_list') {
        return <span>Not enabled at this time</span>
        // return <>
        //     <Form.Item
        //         {...step}
        //         key={`step-output-method-set-ref-${step.key}`}
        //         label="Set Ref"
        //         name={[step.name, 'args', 'output_method', 'args', 'set_ref']}
        //         // rules={[
        //         //     { required: true, message: 'Missing trigger' },
        //         //     { type: 'enum', enum: ['form_submit'], message: 'Not available to you. Sign up for access.'}
        //         // ]}
        //         >
        //             <Input />
        //     </Form.Item>
        // </>
    }
    return <span>output {output_method}</span>
}


function WorkflowStepFormForStepType({
    step, workflowPrompts, getRefsForStep, form, step_type, clearStepOutputArgs, setStepTemplateVars, setStepPromptId
}: {
  step: FormListFieldData
  workflowPrompts: PromptType[]
  getRefsForStep: (step: FormListFieldData) => SelectProps['options']
  form: FormInstance,
  step_type: WorkflowConfigStepTypes
  clearStepOutputArgs: (step: FormListFieldData) => void
  setStepTemplateVars: (step: FormListFieldData, availableFields: string[]) => void
  setStepPromptId: (step: FormListFieldData, prompt_id: string) => void
}) {
    if (step_type === undefined || step_type === null) {
        return <></>
    }

    if (step_type === "execute_prompt"){

        return <WorkflowStepFormExecutePrompt step={step} workflowPrompts={workflowPrompts} getRefsForStep={getRefsForStep} form={form} clearStepOutputArgs={clearStepOutputArgs} setStepTemplateVars={setStepTemplateVars} setStepPromptId={setStepPromptId}/>
        // return workflowStepFormExecutePrompt(step)
    }

    if (step_type === "send_email"){
        return <WorkflowStepFormSendEmail step={step} getRefsForStep={getRefsForStep} form={form} clearStepOutputArgs={clearStepOutputArgs} />

        // return workflowStepFormSendEmail(step)
    }

    if (step_type === "set_project_snippet"){
        return <WorkflowStepFormSetProjectSnippet step={step} getRefsForStep={getRefsForStep} form={form} clearStepOutputArgs={clearStepOutputArgs} />

        // return workflowStepFormSendEmail(step)
    }

    if (step_type === "set_result"){
        return <WorkflowStepFormSetResult step={step} getRefsForStep={getRefsForStep} form={form} clearStepOutputArgs={clearStepOutputArgs} />

        // return workflowStepFormSendEmail(step)
    }

    if (step_type === "lookup_info_set"){
        return <WorkflowStepFormLookupInfoSet step={step} getRefsForStep={getRefsForStep} form={form} clearStepOutputArgs={clearStepOutputArgs} />

    // return workflowStepFormSendEmail(step)
    }

    if (step_type === "run_workflows"){
        return <WorkflowStepFormRunWorkflows step={step} getRefsForStep={getRefsForStep} form={form} clearStepOutputArgs={clearStepOutputArgs} />

    // return workflowStepFormSendEmail(step)
    }

    if (step_type === "post_to_x") {
        return <WorkflowStepFormPostToX step={step} getRefsForStep={getRefsForStep} form={form} clearStepOutputArgs={clearStepOutputArgs} />
    }


    return <></>
}

const cleanWorkflowConfig = (workflowConfig: any): WorkflowConfigType => {
    // const cleanWorkflowConfig
    return {
        triggers: workflowConfig.triggers.map((trigger: WorkflowTrigger) => {
            if (trigger.trigger_type === 'form_submit') {
                return trigger
            } else if (trigger.trigger_type === 'timed') {
                return {
                    ...trigger,
                    args: {
                        ...trigger.args,
                        schedule: {
                            ...(trigger.args.schedule.type === 'daily' ? {type: trigger.args.schedule.type} : {}),
                            ...(trigger.args.schedule.type === 'weekly' ? {type: trigger.args.schedule.type, week_days: trigger.args.schedule.week_days} : {}),
                            ...(trigger.args.schedule.type === 'monthly' ? {type: trigger.args.schedule.type, month_days: trigger.args.schedule.month_days} : {}),
                        }

                    }
                }
            } else {
                return trigger
            }
        }),
        step_definition: {
            schema_version: '1',
            args: {
                block_steps: workflowConfig.step_definition.args.block_steps.map((step: WorkflowBlockStepType) => {
                    if (step.block_step_type === 'execute_prompt') {
                        return {
                            ...step,
                            args: {
                                ...step.args,
                                set_template_vars: (step.args.set_template_vars || []).map((templateVar) => {
                                    // if ('upstream_ref' in templateVar && templateVar.upstream_ref !== '') {

                                    // }
                                    return {
                                        field: templateVar.field,
                                        ...('upstream_ref' in templateVar && templateVar.upstream_ref !== undefined && templateVar.upstream_ref !== '' && templateVar.upstream_ref !== 'Hardcoded' ? {upstream_ref: templateVar.upstream_ref} : {value: templateVar.value || ''}),
                                        // ...('upstream_ref' in templateVar && templateVar.upstream_ref === "Hardcoded" ? {value: templateVar.value || ''} : {})
                                    }
                                })
                            }

                        }
                    } else if (step.block_step_type === 'send_email') {
                        return {
                            ...step,
                            args: {
                                ...step.args,
                                set_template_vars: (step.args.set_template_vars || []).map((templateVar) => {
                                    // if ('upstream_ref' in templateVar && templateVar.upstream_ref !== '') {

                                    // }
                                    return {
                                        field: templateVar.field,
                                        ...('upstream_ref' in templateVar && templateVar.upstream_ref !== undefined && templateVar.upstream_ref !== '' && templateVar.upstream_ref !== 'Hardcoded' ? {upstream_ref: templateVar.upstream_ref} : {value: templateVar.value || ''}),
                                        // ...('upstream_ref' in templateVar && templateVar.upstream_ref === "Hardcoded" ? {value: templateVar.value || ''} : {})
                                    }
                                })
                            }

                        }
                    } else {
                        return step
                    }
                })
            }
        }
    }
}



export interface WorkflowConfigurationFormParams {
  workflow: WorkflowType
  workflowPrompts: PromptType[]
  projectSnippets: ProjectSnippetType[]
  onSave: () => void
}

type StepLabelMap = {
    [key in WorkflowConfigStepTypes]: string
}

const step_type_label_map: StepLabelMap = {
    execute_prompt: "Execute Prompt",
    send_email: "Send an Email",
    set_project_snippet: "Set Project Snippet",
    set_result: "Set Result",
    lookup_info_set: "Lookup Info",
    run_workflows: "Run Workflows",
    post_to_x: "Post to X",
}

const lookup_step_type_label = (step_type: WorkflowConfigStepTypes) => {
    return step_type_label_map[step_type]
}



export function WorkflowConfigurationForm({
    workflow,
    workflowPrompts,
    projectSnippets,
    onSave,
}: WorkflowConfigurationFormParams) {
    const account = useAppSelector(selectAccount);

    const isWorkflowDiscordUsernamePermitted = useAppSelector(selectIsWorkflowDiscordUsernamePermitted)
    const [loadedConfig, setLoadedConfig] = useState<WorkflowConfigType | null>(null)
    const [hideUpgradeSuggestion, setHideUpgradeSuggestion] = useState(false)

    const [form] = Form.useForm();
    const formTriggers = Form.useWatch('triggers', form);
    const formStepDefinition = Form.useWatch('step_definition', form);
    // const formThrottle = Form.useWatch('throttle', form);

    const [workflowDirtyState, setWorkflowDirtyState] = useState<WorkflowType | null>(null);

    const config = workflowDirtyState?.config || workflow?.config
    // const [integrations, setIntegrations] = useState<Integration[] | null>(null);

    const { info_sets } = useInfoSets(account);
    const { data_sets } = useDataSets(account);
    const { integrations } = useIntegrations(account);


    const logit = (foo: any) => {
        console.log(foo)
        console.log(formTriggers)
        return true
    }

    const onWorkflowConfigSave = (values: any) => {
        console.log('Received values of form:', values);
        const cleanedConfig = cleanWorkflowConfig(values)
        values.step_definition.schema_version = '1'
        setLoadedConfig(cleanedConfig)
        editWorkflow(workflow.id, {config: cleanedConfig}).then((res) => {
            console.log(res)
            if (res.status === 200) {
                console.log(res.data)
                message.info("Saved!")
                onSave()
            } else {
                console.log(res.data)
                message.error("Failure!")
            }
        })
    };

    const workflowIntegrationField = (action: FormListFieldData, type_filter: string) => {
        return (
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                    prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                }
            >
                {() => (
                    <Form.Item
                        {...action}
                        label="Integration"
                        name={[action.name, 'args', 'integration_id']}
                        rules={[{ required: true, message: 'Missing integration' }]}
                        extra={<Link to={`/integrations/`}>Manage Integrations</Link>}
                    >
                        <Select>
                            {integrations?.filter((integration) => integration.type === type_filter).map((integration) => (
                                <Select.Option key={integration.id} value={integration.id}>
                                    {integration.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}
            </Form.Item>
        )
    }

    const workflowActionFormDiscord = (action: FormListFieldData) => {
        return (
            <div>
                {workflowIntegrationField(action, 'discord_webhook')}
                <Form.Item
                    {...action}
                    key={`message-${action.key}}`}
                    label="Message"
                    name={[action.name, 'args', 'message']}
                    rules={[{ required: true, message: 'Missing message' }]}
                    // extra={<>The message to send. Special replacements are: {'{symbol}'}, {'{value}'}, {'{context.[field]}'}</>}
                    extra={<>The message to send. Special replacements are: {'{symbol}'} and {'{value}'}.</>}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    {...action}
                    key={`username-${action.key}}`}
                    label="Username"
                    name={[action.name, 'args', 'username']}
                    // rules={[{ required: true, message: 'Missing message' }]}
                    extra={isWorkflowDiscordUsernamePermitted ? <>Username to send as.</> : <>Username to send as. You do not have this feature to specify a Discord Username in a Workflow.</>}
                >
                    <Input disabled={!isWorkflowDiscordUsernamePermitted} placeholder='PyroPrompts' />

                </Form.Item>
            </div>
        )
    }

    const workflowActionFormTelegram = (action: FormListFieldData) => {
        return (
            <div>
                {workflowIntegrationField(action, 'telegram_token')}
                <Form.Item
                    {...action}
                    key={`message-${action.key}}`}
                    label="Message"
                    name={[action.name, 'args', 'message']}
                    rules={[{ required: true, message: 'Missing message' }]}
                    // extra={<>The message to send. Special replacements are: {'{symbol}'}, {'{value}'}, {'{context.field}'}</>}
                    extra={<>The message to send. Special replacements are: {'{symbol}'} and {'{value}'}.</>}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    {...action}
                    key={`chat_id-${action.key}}`}
                    label="Chat ID"
                    name={[action.name, 'args', 'chat_id']}
                    rules={[{ required: true, message: 'Missing Chat ID' }]}
                    extra={<>Telegram Chat ID of where to send this</>}
                >
                    <Input />
                </Form.Item>
            </div>
        )
    }


    // const clearActionArgs = (action: FormListFieldData) => {
    //     console.log("clear", {action})
    //     form.setFieldValue(["actions", action.name, "args"], {})

    // }

    const clearTriggerArgs = (trigger: FormListFieldData) => {
        console.log("clear trigger", {trigger})
        form.setFieldValue(["triggers", trigger.name, "args"], {})

    }

    const defaultExecutePromptStep = () => {
        return {block_step_type: "execute_prompt", args: {model: "gpt-4o-mini", output_method: {output_method_type: "whole_prompt_response", args: {set_ref: `result`}}}}
    }

    const defaultSetResultStep = () => {
        return {block_step_type: "set_result", args: {result_template: "[res]", set_template_vars: [{field: "res", upstream_ref: null}]}}
    }

    const defaultSetProjectSnippetStep = () => {
        return {block_step_type: "set_project_snippet", args: {snippet_name: null, snippet_content_template: "[res]", set_template_vars: [{field: "res", upstream_ref: null}]}}
    }

    const defaultSendEmailStep = () => {
        return {block_step_type: "send_email", args: {subject_template: "A Workflow Ran", "content_markdown_template": "[res]", set_template_vars: [{field: "res", upstream_ref: null}]}}
    }

    const defaultLookupInfoSetStep = () => {
        return {block_step_type: "lookup_info_set", args: {output_method: {output_method_type: "whole_prompt_response", args: {set_ref: `result`}}}}
    }

    const defaultRunWorkflowsStep = () => {
        return {block_step_type: "run_workflows", args: {output_method: {output_method_type: "whole_prompt_response", args: {set_ref: `workflows result`}}}} // TODO
    }

    const defaultPostToXStep = () => {
        return {block_step_type: "post_to_x", args: {output_method: {output_method_type: "whole_prompt_response", args: {set_ref: `result`}}}}
    }

    const getDefaultArgsForStepType = (step_type: string) => {
        if (step_type === 'execute_prompt') {
            return defaultExecutePromptStep().args
        } else if (step_type === 'set_result') {
            return defaultSetResultStep().args
        } else if (step_type === 'set_project_snippet') {
            return defaultSetProjectSnippetStep().args
        } else if (step_type === 'send_email') {
            return defaultSendEmailStep().args
        } else if (step_type === 'lookup_info_set') {
            return defaultLookupInfoSetStep().args
        } else if (step_type === 'run_workflows') {
            return defaultRunWorkflowsStep().args
        } else if (step_type === 'post_to_x') {
            return defaultPostToXStep().args
        } else {
            return {}
        }
    }

    const clearStepArgs = (step: FormListFieldData, new_step_type: string) => {
        console.log("clear step", {step, new_step_type})
        const newArgs = getDefaultArgsForStepType(new_step_type)
        form.setFieldValue(["step_definition", "args", "block_steps", step.name, "args"], newArgs)

    }

    const clearStepOutputArgs = (step: FormListFieldData) => {
        console.log("clear step output", {step})
        form.setFieldValue(["step_definition", "args", "block_steps", step.name, "args", "output_method", "args"], {})

    }

    const setStepPromptId = (step: FormListFieldData, prompt_id: string) => {

        form.setFieldValue(["step_definition", "args", "block_steps", step.name, "args", "prompt_id"], prompt_id)

    }

    const setStepTemplateVars = (step: FormListFieldData, availableFields: string[]) => {
        console.log("set step template vars", {step, availableFields})
        const existingTemplateVars = form.getFieldValue(["step_definition", "args", "block_steps", step.name, "args", "set_template_vars"])
        const newTemplateVars = availableFields.map((field) => {
            const existingFields = ((existingTemplateVars || []).filter((templateVar: WorkflowBlockStepTemplateVarType) => templateVar.field === field) || [])
            return existingFields && existingFields.length ? existingFields[0] : {field}
        })
        console.log("set step template vars newTemplateVars", {step, availableFields, newTemplateVars})

        form.setFieldValue(["step_definition", "args", "block_steps", step.name, "args", "set_template_vars"], newTemplateVars)

    }

    const getRefsForStep = (step: FormListFieldData): SelectProps["options"] => {
        // const project_refs = projectSnippets.map((p) => `Project Snippet: ${p.name}`)
        // const project_refs = projectSnippets.map((p) => p.name)
        const functional_refs = [
            {label: "Functional: Current Date UTC", value: "Current Date"}
        ]
        const project_refs = projectSnippets.map((p) => ({label: `Project Snippet: ${p.name}`, value: p.name}))

        const submission_refs = (
            formTriggers
            // .filter((t: WorkflowTrigger) => t.trigger_type === "form_submit")
                .map((t: WorkflowTrigger) => t.args.submission_params || [])
                .reduce((accumulator: FormSubmissionField[], t: FormSubmissionField) => accumulator.concat(t), [])
            // .map((f: FormSubmissionField) => `Form: ${f.field}`)
            // .map((f: FormSubmissionField) => f.field)
                .map((f: FormSubmissionField) => ({label: `Form: ${f.field}`, value: f.field}))

        )

        const steps: WorkflowBlockStepType[] = form.getFieldValue(["step_definition", "args", "block_steps"])
        console.log("steps", {step, steps})
        const steps_in_scope = (
            steps.map((step, i) => ({i, step}))
                .filter((index_wrapped_step) => index_wrapped_step.i < step.name)
                .map((index_wrapped_step) => index_wrapped_step.step)
        )
        const whole_prompt_refs = (
            steps_in_scope
                .map((s: WorkflowBlockStepType) => (s.block_step_type === 'execute_prompt' || s.block_step_type === 'lookup_info_set' || s.block_step_type === 'run_workflows' || s.block_step_type === 'post_to_x') && s.args.output_method?.output_method_type === 'whole_prompt_response' ? s.args.output_method.args.set_ref : null)
                .filter((s: string | null | undefined) => s !== null && s !== undefined)
                .map((s) => ({label: `Step: ${s}`, value: s}))
            // .map((s) => `Step: ${s}`)
        )
        const final_refs = [...project_refs, ...submission_refs, ...whole_prompt_refs, ...functional_refs]
        const deduped_refs = final_refs.filter((r) => {
            const matching_refs = final_refs.filter((f) => f.value === r.value)
            if (matching_refs.length > 1) {
                if (r.label === matching_refs[matching_refs.length - 1].label) {
                    return true
                }
                return false
            }
            return true
        })
        console.log("getRefsForStep", {final_refs, deduped_refs, project_refs, submission_refs, whole_prompt_refs})
        return deduped_refs
    }

    const getTriggerTitle = (trigger_name: number) => {
        if (formTriggers[trigger_name].trigger_type === 'timed') {
            const schedule_type = formTriggers[trigger_name].args.schedule?.type || 'daily'
            if (schedule_type === 'weekly') {
                const days_of_week = formTriggers[trigger_name].args.schedule?.week_days
                if (days_of_week) {
                    if (days_of_week.length >= 7) {
                        return "Scheduled Daily"
                    }
                    if (days_of_week.length <= 3) {
                        const days_of_week_labels = days_of_week.map((day: string) => weeklyLabelsByValue[day] || undefined).filter((label: string | undefined) => label !== undefined)
                        return `Scheduled Weekly on ${days_of_week_labels.join(', ')}`

                    }
                    return `Scheduled Weekly on ${days_of_week.join(', ')}`
                }
                return `Scheduled Weekly`
            }
            if (schedule_type === 'monthly') {
                const month_days = formTriggers[trigger_name].args.schedule?.month_days
                if (month_days.length > 7) {
                    return `Scheduled Monthly on ${month_days.length} days`

                }
                return `Scheduled Monthly on day ${month_days.join(', ')}`
            }
            return `Scheduled ${toTitleCase(schedule_type)}`
        } else if (formTriggers[trigger_name].trigger_type === 'form_submit') {
            // .args.schedule?.type || 'daily'
            const submission_params = (formTriggers[trigger_name].args.submission_params || []).map((submission_param: FormSubmissionField) => submission_param.label || submission_param.field)
            if (submission_params.length > 0) {
                return `Form Submission with fields: ${submission_params.join(', ')}`
            } else {
                return `Form Submission with no fields`
            }

        }
        return "No Trigger"
    }

    const getStepLabel  = (step: WorkflowBlockStepType) => {
        const label = lookup_step_type_label(step.block_step_type)
        if (step.block_step_type === "lookup_info_set") {
            const info_set_id = step.args.info_set_id
            const used_info_set = (info_sets || []).find(info_set => info_set.id === info_set_id)
            if (used_info_set) {
                if (used_info_set.source_type === "data_set_search" || used_info_set.source_type === "data_set_sql") {
                    const data_set_id = step.args.source_lookup_params.data_set_id || null;
                    if (data_set_id !== null) {
                        const chosen_data_set = (data_sets || []).find(data_set => data_set.id === data_set_id);
                        return `${used_info_set.name} on ${chosen_data_set?.name}`

                    }
                }
                return `${label} - ${used_info_set.name}`
            }
        }
        if (step.block_step_type === "set_project_snippet") {
            const snippet_name = step.args.snippet_name
            if (snippet_name) {
                return `${label} - ${snippet_name}`
            }
        }
        if (step.block_step_type === "send_email") {
            const subject_template = step.args.subject_template
            if (subject_template) {
                return `${label} with Subject '${subject_template}'`
            }
        }
        if (step.block_step_type === "execute_prompt") {
            const prompt_id = step.args.prompt_id
            if (!prompt_id) {
                return `${label} - No Prompt`
            }
            const matchingPrompts = workflowPrompts.filter((p) => p.id === prompt_id)
            if (matchingPrompts.length > 0) {
                const prompt = matchingPrompts[0]
                if (prompt.name) {
                    // return `${label} - ${prompt.name}`
                    // first 80 characters of the name
                    if (prompt.name.length > 80) {
                        return `${label} - ${prompt.name.slice(0, 80)}...`
                    }
                    return `${label} - ${prompt.name}`
                }
            }

        }
        if (step.block_step_type === 'post_to_x') {
            const integration_id = step.args.integration_id
            if (integration_id) {
                const integration = (integrations || []).find((i) => i.id === integration_id)
                if (integration) {
                    return `${label} - ${integration.name}`
                }
            }
        }
        return label
    }

    const upgradePromptModels = (oldModel: string, newModel: string) => {
        const paths_to_update = (config.step_definition?.args.block_steps || []).map((step, i) => ({step, path:["step_definition", "args", "block_steps", i, "args", "model"]})).filter((step_path) => step_path.step.block_step_type === 'execute_prompt' && step_path.step.args.model === oldModel).map((step_path) => step_path.path)
        // form.setFieldValue(["step_definition", "args", "block_steps", step.name, "args", "source_lookup_params"], defaults)
        setHideUpgradeSuggestion(true)
        paths_to_update.forEach((path) => {
            console.log("setting path", {path})
            form.setFieldValue(path, newModel)
        })

    }

    const steps_use_execute_prompt_on_35 = (config.step_definition?.args.block_steps || []).some(step => step.block_step_type === 'execute_prompt' && step.args.model.includes("gpt-") && step.args.model.includes("-3.5"))
    // formStepDefinition


    return (
        <>

            <Form
                form={form}
                name="workflow_configuration"
                onFinish={onWorkflowConfigSave}
                autoComplete="off"
                requiredMark={"optional"}
                // style={{width: "500px"}}
                // initialValues={workflow.config && workflow.config.triggers?.length > 0 ? workflow.config : {
                //     throttle: 'starts_matching',
                //     triggers: [
                //         {trigger_type: 'data_set_symbol', args: {data_set_id: null, thresholds: [{field: 'value', operator: "eq", value: 1}]}}
                //     ],
                //     actions: [{}]
                // }}
                initialValues={workflow.config && workflow.config.triggers?.length > 0 ? workflow.config : {
                    triggers: [
                        {
                            "trigger_type": "form_submit",
                            "args": {
                                "submission_params": [
                                    // {"field": "job description"}
                                ]
                            }
                        }
                    ],
                    step_definition: {
                        "schema_version": '1',
                        "args": {
                            "block_steps": [
                                {
                                    "block_step_type": "execute_prompt",
                                    "args": {
                                        "prompt_id": null,
                                        "model": "gpt-4o-mini",
                                        "output_method": {
                                            "output_method_type": "whole_prompt_response",
                                            "args": {},
                                        },
                                        "set_template_vars": [],
                                    }
                                }
                            ]
                        }
                    },
                }}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
            >

                <Form.Item style={{float: "right"}}>
                    <Button type="primary" htmlType="submit">
                  Save
                    </Button>
                </Form.Item>
                <Typography.Title level={2}>Triggers</Typography.Title>


                <Typography.Paragraph>When a workflow is triggered, it will run through the steps that you configure.</Typography.Paragraph>



                <Form.List name="triggers">
                    {(triggers, { add: addTrigger, remove: removeTrigger }) => (
                        <>
                            {triggers.map(trigger => (

                                <Collapse
                                    defaultActiveKey={'trigger'}
                                    style={{marginBottom: '8px'}}
                                    key={`trigger-card-${trigger.key}`}
                                    items={
                                        [
                                            {
                                                key: workflow.enabled ? 'none' : 'trigger',
                                                label: formTriggers && formTriggers[trigger.name] ? (getTriggerTitle(trigger.name)) : 'No Trigger',
                                                children: <div key={`trigger-${trigger.key}`}>
                                                    {/* <div style={{border: "1px solid #333333", padding: '10px', margin: "5px 0px"}} key={`trigger-${trigger.key}`}> */}
                                                    {triggers.length > 1 && (
                                                        <Tooltip placement='left' title="Remove Trigger">
                                                            <MinusCircleOutlined style={{float: "right"}} onClick={() => removeTrigger(trigger.name)} />
                                                        </Tooltip>
                                                    )}

                                                    {/* <WorkflowTriggerForm trigger={trigger} /> */}

                                                    <Form.Item
                                                        {...trigger}
                                                        key={`trigger-${trigger.key}`}
                                                        label="Trigger"
                                                        name={[trigger.name, 'trigger_type']}
                                                        rules={[
                                                            { required: true, message: 'Missing trigger' },
                                                            { type: 'enum', enum: ['form_submit', 'timed'], message: 'Not implemented yet.'}
                                                        ]}
                                                    >
                                                        <Select onChange={() => clearTriggerArgs(trigger)}>
                                                            <Select.Option key={'form_submit'} value={'form_submit'}>
                                              Form Submission
                                                            </Select.Option>
                                                            <Select.Option key={'timed'} value={'timed'}>
                                              Timed
                                                            </Select.Option>



                                                        </Select>
                                                    </Form.Item>

                                                    {formTriggers && formTriggers[trigger.name] && (
                                                        <WorkflowTriggerFormForTriggerType
                                                            trigger={trigger}
                                                            trigger_type={formTriggers[trigger.name].trigger_type}
                                                            timed_schedule_type={formTriggers[trigger.name].args.schedule?.type || 'daily'}
                                                        />
                                                    // getTriggerFormForTriggerType(trigger, formTriggers[trigger.name].trigger_type)
                                                    )}

                                                    {/* {formConditions && formConditions[trigger.name].args.data_set_id && showSelectedDataSet(trigger)} */}

                                                </div>,
                                            },
                                        ]
                                    }
                                />



                            ))}


                            {triggers.length < 1 && (
                                <Space style={{justifyContent: "space-between", display: "flex"}}>
                                    <span></span>
                                    <Button onClick={() => addTrigger({trigger_type: 'data_set_symbol', args: {data_set_id: null, thresholds: [{field: 'value', operator: "eq", value: 1}]}})} block icon={<PlusOutlined />}>
                                  Add Trigger
                                    </Button>
                                </Space>


                            )}

                        </>
                    )}
                </Form.List>

                <Typography.Title level={2}>Steps</Typography.Title>

                {!hideUpgradeSuggestion && steps_use_execute_prompt_on_35 && (
                    <Alert icon={<AimOutlined />} showIcon message={<Typography.Text>Your workflow uses GPT-3.5, which is older and more expensive. <a onClick={() => upgradePromptModels("gpt-3.5-turbo", "gpt-4o-mini")}>Upgrade to GPT-4o mini</a>.</Typography.Text>} type="info" style={{marginBottom: '12px'}} />

                )}

                <Typography.Paragraph>Then run the following steps:</Typography.Paragraph>
                <Form.List name={["step_definition", "args", "block_steps"]}>
                    {(steps, { add: addStep, remove: removeStep }) => (
                        <>
                            {steps.map((step, i) => (
                                <Collapse
                                    defaultActiveKey={`step-${step.key}`}
                                    style={{marginBottom: '8px'}}
                                    key={`step-${step.key}`}
                                    items={[{
                                        key: workflow.enabled ? 'none' : `step-${step.key}`,
                                        label: `Step ${i + 1}${formStepDefinition && formStepDefinition.args.block_steps[step.name] ? ': ' + getStepLabel(formStepDefinition.args.block_steps[step.name]) || '' : ''}`,
                                        children: <div style={{}} key={`step-${step.key}`}>
                                            {/* <div key={step.key}> */}
                                            {logit(step) && (<p></p>)}


                                            <Form.Item
                                                noStyle
                                                shouldUpdate={(prevValues, curValues) =>
                                                    prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                                                }
                                            >
                                                {() => (
                                                    <Form.Item
                                                        {...step}
                                                        label="Step Type"
                                                        name={[step.name, 'block_step_type']}
                                                        rules={[
                                                            { required: true, message: 'Missing step' },
                                                            { type: 'enum', enum: ['execute_prompt', 'send_email', 'set_project_snippet', 'set_result', 'lookup_info_set', ...(isBetaUser(account?.name) ? ['run_workflows', 'post_to_x'] : [])], message: 'Not available to you currently.'}
                                                        ]}
                                                    >
                                                        <Select onChange={(new_value) => clearStepArgs(step, new_value)}>
                                                            <Select.Option key={'execute_prompt'} value={'execute_prompt'}>
                                                                Execute Prompt
                                                            </Select.Option>
                                                            <Select.Option key={'send_email'} value={'send_email'}>
                                                                Send an Email
                                                            </Select.Option>
                                                            <Select.Option key={'set_project_snippet'} value={'set_project_snippet'}>
                                                                Set Project Snippet
                                                            </Select.Option>
                                                            <Select.Option key={'set_result'} value={'set_result'}>
                                                                Set Result
                                                            </Select.Option>
                                                            <Select.Option key={'lookup_info_set'} value={'lookup_info_set'}>
                                                                Lookup Info
                                                            </Select.Option>
                                                            <Select.Option key={'run_workflows'} value={'run_workflows'}>
                                                                Run Workflows (Beta)
                                                            </Select.Option>
                                                            <Select.Option key={'post_to_x'} value={'post_to_x'}>
                                                                Post to X (Beta)
                                                            </Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                )}
                                            </Form.Item>

                                            {formStepDefinition && formStepDefinition.args.block_steps[step.name] && (
                                                <WorkflowStepFormForStepType
                                                    step={step}
                                                    workflowPrompts={workflowPrompts}
                                                    form={form}
                                                    step_type={formStepDefinition.args.block_steps[step.name].block_step_type}
                                                    getRefsForStep={getRefsForStep}
                                                    setStepTemplateVars={setStepTemplateVars}
                                                    setStepPromptId={setStepPromptId}
                                                    clearStepOutputArgs={clearStepOutputArgs}
                                                />

                                            )}

                                        </div>,
                                        extra:<>
                                            <Space>
                                                {steps.length < MAXIMUM_STEPS && (

                                                    <Tooltip placement='left' title="Duplicate Step">
                                                        <CopyOutlined style={{float: "right"}} onClick={(e) => {
                                                            e.stopPropagation();
                                                            addStep(formStepDefinition.args.block_steps[step.name], i + 1)
                                                        }} />
                                                    </Tooltip>
                                                )}
                                                {steps.length < MAXIMUM_STEPS && (

                                                    <Tooltip placement='left' title="Insert Step After">
                                                        <PlusCircleOutlined style={{float: "right"}} onClick={(e) => {
                                                            e.stopPropagation()
                                                            addStep(defaultExecutePromptStep(), i + 1)
                                                        }} />
                                                    </Tooltip>
                                                )}

                                                {steps.length > 1 && (
                                                    <Tooltip placement='left' title="Remove Step">
                                                        <MinusCircleOutlined style={{float: "right"}} onClick={() => removeStep(step.name)} />
                                                    </Tooltip>
                                                )}
                                            </Space>

                                        </>

                                    }]}
                                />

                            ))}

                            {steps.length < MAXIMUM_STEPS && (
                                <Row>
                                    <Col span={24}>
                                        <Button type='dashed' style={{height: '48px'}} onClick={() => addStep(defaultSetResultStep())} block icon={<PlusOutlined />}>
                                      Add Step
                                        </Button>
                                    </Col>
                                </Row>
                            )}

                        </>
                    )}
                </Form.List>

                <Form.Item style={{marginTop: '12px'}}>
                    <Button type="primary" htmlType="submit">
                  Save
                    </Button>
                </Form.Item>
            </Form>


        </>
    )
}
